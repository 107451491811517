//基本盘
import Vue from 'vue'
import store from './store/core'
//工具函数
import { UAParser } from 'ua-parser-js'
import { scriptInsert } from './libaray/js/tools'
//基本样式
import './portal/basic.scss'
Vue.config.devtools = true;
store.commit('uaInfo/uaInfoSetup', UAParser());
// 是否需要显示特点系统布局，引入系统静态文件
if(window.sessionStorage.getItem('osSelect')) {
    store.commit('uaInfo/osSet', window.sessionStorage.getItem('osSelect'));
}
    const { search } = location;
    const urlParma = new URLSearchParams(search);
    let fileName = (urlParma.get('vc') || window.ANALYSIS_URL).replace(/^(www.)/, '');
    // 如果需要AB测试，则用需要取数据的域名
    if(window.MATOMO_OBJ.matomoaburl) {
        fileName = window.ANALYSIS_URL;
    }
    const serves = urlParma.get('mockserve') || fileName;
    const fileFetch = `https://${serves}/settings/${fileName}.${['Mac OS', 'iOS'].includes(store.state.uaInfo.os) ? 'ios' : 'and'}.js?v=${parseInt(Math.random() * 1000000)}`;
(async () => {
    let result = await scriptInsert(fileFetch).catch(err => console.log(err)),
        refetch = 3;
    while (!result && refetch > 1) {
        await new Promise(resolve => {
            setTimeout(() => { resolve() }, 1000)
        })
        result = await scriptInsert(fileFetch).catch(err => console.log(err));
        refetch--;
    }
    const
        { techCore = 'Core' } = window.confView || {},
        Portal = () => import(`./portal/${techCore}.vue`);
    new Vue({
        store,
        render: h => h(Portal)
    }).$mount('#LandingPage');
})()


