export default {
    namespaced: true,
    state: {
        os: null,  //操作系统  Android, Mac OS, iOS
        device: null,  //设备类型 console, mobile, tablet, smarttv, wearable, embedded
        browser: null, //游览器品牌 Chrome, Chrome WebView, QQBrowser, Mobile Safari
        platform: null, //设备配品牌 Google, HP, HTC, Huawei, Jolla, Lenovo,
    },
    mutations: {
        uaInfoSetup(state, payload) {
            const { browser, device, os } = payload;
            state.os = os.name;
            state.device = device.type;
            state.browser = browser.name;
            state.platform = device.vendor;
        },
        uaInfoSet(state, { name, value }) {
            if (Object.keys(state).includes(name)) {
                state[name] = value;
            } else {
                console.log('no props exist');
            }
        },
        osSet(state, payload) { 
            state.os = payload;
        }
    }
}