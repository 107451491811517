export function scriptInsert(url, sucess, failed) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.onload = () => {
            if (sucess) sucess();
            resolve(true);
        };
        script.onerror = (err) => {
            if (failed) failed();
            reject(err);
        };
        document.getElementsByTagName('head')[0].appendChild(script)
    })
}
export function useSession(key, value) {
    if (value) {
        if (value == 'delete') {
            window.sessionStorage.removeItem(key);
            return false;
        }
        window.sessionStorage.setItem(key, JSON.stringify(value));
    } else {
        return JSON.parse(window.sessionStorage.getItem(key));
    }
}
export function useLocalStore(key, value) {
    if (value) {
        if (value == 'delete') {
            window.localStorage.removeItem(key);
            return false;
        }
        window.localStorage.setItem(key, JSON.stringify(value));
    } else {
        return JSON.parse(window.localStorage.getItem(key));
    }
}
export function lazyComponent(AsyncView, loadView) {
    const AsyncHandler = () => ({
        component: AsyncView,
        loading: loadView,
        error: loadView,
        delay: 0,
        timeout: 10000
    });
    return Promise.resolve({
        functional: true,
        render(h, { data, children }) {
            return h(AsyncHandler, data, children);
        }
    });
}
export function setPageInfo(parma) {
    if (parma.title) {
        document.title = parma.title;
    }
    if (parma.keywords) {
        let meta = document.createElement('meta');
        meta.content = parma.keywords;
        meta.name = 'keywords';
        document.getElementsByTagName('head')[0].appendChild(meta);
    }
    if (parma.description) {
        let meta = document.createElement('meta');
        meta.content = parma.description;
        meta.name = 'description';
        document.getElementsByTagName('head')[0].appendChild(meta);
    }
}

export function GetQueryString(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    let r = window.location.search.substring(1).match(reg);
    if (r != null) return decodeURIComponent(r[2]); return null;
}
//判断用户操作系统及平台
export function isBrowserPc() {

    var sUserAgent = window.navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    var bIsMidp = sUserAgent.match(/midp/i) == "midp";
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    var bIsAndroid = sUserAgent.match(/android/i) == "android";
    var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";

    if (sUserAgent.match(/MicroMessenger/i) == "micromessenger") {
        return 'wx';
    }
    // QQ内置浏览器
    if (sUserAgent.indexOf("qq") > -1 && sUserAgent.indexOf("mqqbrowser") < 0) {
        return 'qq';
    }

    if (
        bIsIpad ||
        bIsIphoneOs ||
        bIsMidp ||
        bIsUc7 ||
        bIsUc ||
        bIsAndroid ||
        bIsCE ||
        bIsWM
    ) {
        if (bIsAndroid) {
            return 'android'
        } else {
            return 'iphone'
        }
    } else {
        return 'pc';
    }
}