/* eslint-disable */
import { scriptInsert, isBrowserPc } from '@/libaray/js/tools'
export default {
    namespaced: true,
    state: {
        anasys: '',
        anasysHm: '',
        googleGTAG: null,
        googleGTM: null,
        sensors: {},
        TinstallLoad: 0,
        TinstallKey: null,
        SuperinstallKey: null,
    },
    mutations: {
        collectSetup(state, payload) {
            const props = ['anasys', 'anasysHm', 'googleGTAG', 'googleGTM', 'TinstallKey', 'SuperinstallKey', 'useSpecGa'];
            props.map(name => {
                if (payload[name]) {
                    state[name] = payload[name];
                }
            })
        },
        collectSet(state, { name, value }) {
            if (Object.keys(state).includes(name)) {
                state[name] = value;
            } else {
                console.log('no props exist');
            }
        }
    },
    actions: {
        fetchSensors({ state }, { server_url }) {
            state.sensors = require('sa-sdk-javascript');
            state.sensors.init({
                server_url,
                show_log: false,
                heatmap: {
                    //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
                    clickmap: 'not_collect',
                    //是否开启触达注意力图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
                    scroll_notice_map: 'not_collect'
                }
            });
            state.sensors.quick('autoTrack');
        },
        fetchTinsall({ state, commit, dispatch }, { server_url = '/tinstall.api.scyfng.min.js', appKey }) {
            scriptInsert(server_url, () => {
                commit('collectSet', { name: 'TinstallKey', value: appKey })
                if (state.TinstallLoad > 0) {
                    dispatch('collectTinsall');
                }
            });
        },
        fetchSuperinsall({ commit }, { appKey, code }) { //SuperInstall 下载方式JS引入
            let InvitationCode = {
                code: code
            }
            let time = 0
            let loop = setInterval(() => {
                try {
                    window.superinstall = SuperInstall;
                    superinstall.init(appKey, 1, InvitationCode);
                    clearInterval(loop);
                } catch (error) {
                    if (time > 10) clearInterval(loop);
                    time++;
                    console.log(error);
                }
            }, 1000);
        },
        sensorsQuick({ state }, { $event, $element_content }) {
            if (state.sensors.quick) {
                state.sensors.quick('trackAllHeatMap', $event?.target || {}, {
                    $element_content,
                }, () => {
                    console.log($event);
                });
            }
        },
        sensorsTrack({ state }, { CustomWebClick, $is_first_time, webclick_name, webclick_category, webclick_pagename, webclick_pagecate }) {
            state.sensors.track(CustomWebClick, {
                $is_first_time,
                webclick_name,
                webclick_category,
                webclick_pagename,
                webclick_pagecate
            }, () => {
                console.log("success");
            });
        },
        collectTinsall({ state, commit }) {
            if (window?.TInstall?.getInstall) {
                let time = 0,
                    loop = setInterval(() => {
                        try {
                            let params = window.TInstall.parseUrlParams();
                            window.TInstall.getInstall(state.TinstallKey, params, (res) => { console.log(res) });
                            clearInterval(loop);
                        } catch (error) {
                            if (time > 10) clearInterval(loop);
                            time++;
                            console.log(error);
                        }
                    }, 1000);
            } else {
                commit('collectSet', { name: 'TinstallLoad', value: state.TinstallLoad + 1 });
            }
        },
        fetchOnlyYouSensors({ state }, { server_url }) {
            state.sensors = require('sa-sdk-javascript');
            state.sensors.init({
                server_url,
                is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
                use_client_time: true,
                show_log: false,
                send_type: 'beacon',
                is_track_device_id: true, // 自動採集 device id
                // is_track_single_page: true, // 單頁 hash 切換追蹤
                heatmap: {
                    //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
                    clickmap: 'default',
                    //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
                    scroll_notice_map: 'default'
                }
            });
            // 預置屬性

            let sensorGlobalProps = {
                product_id: "A18",
                ldy_type: "original"
            }
            // 取網址參數
            var urlParametersObj = location.search.substring(1) !== '' ? JSON.parse('{"' + decodeURI(location.search.substring(1).replace(/&/g, "\",\"").replace(/=/g, "\":\"")) + '"}') : {};
            if (urlParametersObj.code) { // 推廣渠道
                sensorGlobalProps.invite_code = urlParametersObj.code;
            }

            if (isBrowserPc() == "pc") {
                sensorGlobalProps.ldy_type = "PC"
            }

            state.sensors.registerPage(sensorGlobalProps);
            state.sensors.quick('autoTrack');
        },
        sensorsOnlyYouTrack({ state }, { event, obj }) {
            state.sensors.track(event, {
                product_id: "A18",
                ...obj
            }, () => {
                console.log("success");
            });
        },
    }
}