import Vue from 'vue'
import Vuex from 'vuex'
import uaInfo from './uaInfo'
import global from './global'
import collector from './collector'
Vue.use(Vuex)
export default new Vuex.Store({
    modules: {
        uaInfo,
        global,
        collector
    }
})