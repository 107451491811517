import { setPageInfo } from '../libaray/js/tools'
export default {
    namespaced: true,
    state: {
        brand: '', //落地页APP品牌名，
        brandEn: '',// //落地页APP品牌名英文，
        logo: null, //产品icon图表
        slogan: null, //产品标语
        promo: null, //是否显示广告[off||2:隐藏，其他情况都为显示广告]
        promoCode: null, //接入广告ID[也用作产品ID区分产品线用]
        commercial: '', //招商合作
        businessLink: '', //招商合作跳转
        marquee: '',//跑马灯文字
        customerCode: false,//接入客服配置,
        usePayProcess: false,
        serverLink: false, //多次连续点击客服链接
        title: {
            "qiezi": "果酱视频官网|APP下载——果酱视频 果然好片",
            "xiaoyou": "小笛视频官网|APP下载——小笛视频 吹出一手好湿",
            "xiangjiao": "香蕉视频官网|APP下载——香蕉视频 剥皮见真心！",
            "caomei": "草莓视频官网|APP下载——草莓视频 为草而生",
            "naicha": "奶茶视频官网|APP下载——奶茶视频 有容奶大",
            "luobo": "萝卜视频官网|APP下载——萝卜视频 勃起来才够劲！",
            "boluo": "菠萝视频官网|APP下载——菠萝视频 爱就是要做出来",
            "yingtao": "樱桃视频官网|APP下载——樱桃视频 硬起来无处可逃",
            "mangguo": "芒果视频官网|APP下载——芒果视频 原来里面更黄",
            "yumi": "玉米视频官网|APP下载——玉米视频 颗粒的舒爽体验",
            "mogu": "蘑菇视频官网|APP下载——蘑菇视频 造爱の小屋",
            "miju": "蜜桔视频官网|APP下载——蜜桔视频 让性不再神秘",
            "ganzhe": "甘蔗视频官网|APP下载——甘蔗视频 来日方长",
            "szs": "色中色官网|APP下载——SexInSex极致の欲望",
            "xingba": "杏吧APP下载|性吧|Sex8——春暖花开 杏吧有你",
            "chaopeng": "超碰视频官网|APP下载——caopron碰撞爱的火花",
            "gcf2d": "cilicili官网|APP下载——cilicili，so sexy！",
            "myg": "JAV名优馆官网|APP下载—— 满足你所有的渴望",
            "zmw": "字幕网官网|APP下载——有字幕原来可以更黄的！",
            "jiucao": "久草视频官网|APP下载——久草视频 爱她就草她！",
            "caoliu": "草榴视频官网|APP下载——草榴社区 永远的1024！"
        }
    },
    mutations: {
        globalSetup(state, payload) {
            const props = ['brand', 'brandEn', 'promo', 'promoCode', 'commercial', 'businessLink', 'marquee', 'usePayProcess','customerCode'],
                { seoTitle, seokeywords, seoDescription } = payload;
            props.map(name => {
                if (payload[name]) {
                    state[name] = payload[name];
                }
            });
            const titles = state.title;
            setPageInfo({
                title: seoTitle || titles[payload.promoCode] || '',
                keywords: seokeywords || '',
                description: seoDescription || ''
            })
        },
        globalSet(state, { name, value }) {
            if (Object.keys(state).includes(name)) {
                state[name] = value;
            } else {
                console.log('no props exist');
            }
        },
        logoSet(state, payload) {
            state.logo = payload;
        }
    },
    actions: {

    }
}

